import React from "react";
import ContactForm from "../../Components/forms/ContactForm";

import { useForm, ValidationError } from "@formspree/react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLinkedin, faTwitter, faGithub } from "@fortawesome/free-brands-svg-icons";

const ContactMe = () => {
  const [state, handleSubmit] = useForm("mzbnegzl");
  if (state.succeeded) {
    return (
      <div className="container mx-auto rounded-3xl w-full h-full text-[#002C6D] px-5 lg:px-16 font-jakarta">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="w-full py-10 lg:pr-10">
            <h2 className="text-xl lg:text-4xl font-semibold text-center px-5">
              Thank you for reaching out to me. <br /> I will get back to you soon!
            </h2>
            <h3 className="my-5 text-lg">Connect with me via</h3>
            <div className="flex items-center justify-center px-5 gap-4">
              <a href="https://github.com/mahendraketut" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github text-2xl"></i>
              </a>

              <a href="https://www.linkedin.com/in/mahendraketut" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin text-2xl"></i>
              </a>

              <a href="https://www.instagram.com/mahendraketut_" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram text-2xl"></i>
              </a>
              <a href="https://wa.me/6282147033064" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-whatsapp text-2xl"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <section id="contact" className="w-full h-full px-5 lg:px-0 py-20 bg-[#002C6D] font-jakarta">
      <div className="container mx-auto  rounded-3xl w-full h-full text-white px-5 lg:px-16">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="w-full lg:w-1/2 py-10 lg:pr-10">
            <h2 className="text-4xl lg:text-6xl font-semibold text-left px-5">
              Have a project idea. <br></br>Let’s work together!
            </h2>
            <div className="flex items-center justify-start px-5">
              <div className="w-20 h-1 bg-white mt-5 mb-10"></div>
            </div>
            <p className="text-lg lg:text-xl text-left px-5 lg:leading-loose">I am available for freelance work. Connect with me via email or phone.</p>
            <h3 className="my-5 text-lg text-left ms-5">Connect with me via</h3>
            <div className="flex items-center justify-start px-5 gap-4">
              <a href="https://github.com/mahendraketut" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github text-2xl"></i>
              </a>

              <a href="https://www.linkedin.com/in/mahendraketut" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin text-2xl"></i>
              </a>

              <a href="https://www.instagram.com/mahendraketut_" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram text-2xl"></i>
              </a>
            </div>
          </div>
          <div className="w-full lg:w-1/2 py-10">
            <ContactForm></ContactForm>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactMe;

import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className=" bg-[#002C6D] text-white font-jakarta  border-t border-blue-900">
      <div className="flex items-center justify-center w-full h-20 px-4 py-6 sm:px-8 lg:px-16">
        <p className="text-sm font-normal">
          © {year} - Built by{" "}
          <a
            href="
        https://www.linkedin.com/in/mahendraketut"
            target="_blank"
            className="text-gray-50"
          >
            I Ketut Mahendra
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { motion } from "framer-motion";

const WorkingExperienceCard = ({ logo, title, link, type, subtitle, date, description }) => {
  return (
    <div className="flex items-center mb-5">
      <div className="w-20 h-20 rounded-lg bg-white overflow-hidden">
        <motion.img
          src={logo}
          alt="logo"
          className="object-cover rounded-lg shadow-sm w-full h-full"
          whileHover={{ scale: 1.2 }} // Zoom in on hover
        />
      </div>

      <div className="flex flex-col items-start justify-start ms-4 w-3/4">
        <a href={link} target="blank" className="text-xl font-semibold text-blue-800 text-start">
          {title}
        </a>
        <h2 className="text-md font-medium text-left text-gray-600">
          {subtitle} - {type}
        </h2>
        <h3 className="text-sm font-normal text-gray-500">{date}</h3>
        <p className="text-sm font-normal text-left text-gray-500">{description}</p>
      </div>
    </div>
  );
};

export default WorkingExperienceCard;

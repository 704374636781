import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { myPortfolio, dcmc, promo, school } from "../../constant/projects";
import ProjectCard from "../../Components/cards/ProjectsCard";

const portfolioSection = () => {
  const styleBg = {
    transition: "background-color 0.3s ease" /* Add transition for smoother effect */,
    backgroundColor: "rgb(219, 234, 254)" /* Initial background color */,
    backgroundImage:
      "radial-gradient(at 63.77% 67.17%, rgb(255, 255, 255) 0, transparent 77%), radial-gradient(at -2.49% -1.50%, rgb(147, 197, 253) 0, transparent 68%), radial-gradient(at 98.17% 0.67%, rgb(219, 234, 254) 0, transparent 43%)",
  };

  const projects = [
    {
      title: "Portfolio Website",
      description: "I developed my portfolio website using modern web technologies like React and Tailwind CSS. I am responsible for creating a responsive and user-friendly interface.",
      image: myPortfolio,
      github: "",
      demo: "https://mahendraketut.my.id",
      technology: [
        {
          name: "React",
          url: "https://reactjs.org/",
          image: "https://cdn.worldvectorlogo.com/logos/react-2.svg",
        },
        {
          name: "Tailwind CSS",
          url: "https://tailwindcss.com/",
          image: "https://cdn.worldvectorlogo.com/logos/tailwindcss.svg",
        },
        {
          name: "Framer Motion",
          url: "https://www.framer.com/motion/",
          image: "https://cdn.worldvectorlogo.com/logos/framer-motion.svg",
        },
        // {
        //   name: "Swiper JS",
        //   url: "https://swiperjs.com/",
        //   image: "https://cdn.worldvectorlogo.com/logos/swiper.svg",
        // },
      ],
    },
    {
      title: "DCMC (Dental Clinic Management Centre)",
      description: "I developed a web application for managing dental clinics using modern technologies like Laravel and Bootstrap. I am responsible for creating a responsive and user-friendly interface.",
      image: dcmc,
      github: "https://github.com/mahendraketut/DCMC",
      demo: "https://dcmc.mahendraketut.my.id",
      technology: [
        {
          name: "Laravel",
          url: "https://laravel.com/",
          image: "https://cdn.worldvectorlogo.com/logos/laravel-3.svg",
        },
        {
          name: "Bootstrap",
          url: "https://getbootstrap.com/",
          image: "https://cdn.worldvectorlogo.com/logos/bootstrap-5-1.svg",
        },
        {
          name: "MySQL",
          url: "https://www.mysql.com/",
          image: "https://cdn.worldvectorlogo.com/logos/mysql-logo.svg",
        },
        {
          name: "PHP",
          url: "https://www.php.net/",
          image: "https://cdn.worldvectorlogo.com/logos/php-1.svg",
        },
      ],
    },
    {
      title: "Promotourism",
      description: "I developed a web application for promoting tourism using modern technologies like Angular, Express, Node JS, MongoDB and Tailwind CSS. I am responsible for creating a responsive and user-friendly interface.",
      image: promo,
      github: "https://github.com/mahendraketut/promo-tourism",
      demo: "https://promotourism.mahendraketut.my.id",
      technology: [
        {
          name: "Angular",
          url: "https://angular.io/",
          image: "https://cdn.worldvectorlogo.com/logos/angular-icon-1.svg",
        },
        {
          name: "Tailwind CSS",
          url: "https://tailwindcss.com/",
          image: "https://cdn.worldvectorlogo.com/logos/tailwind-css-2.svg",
        },
        {
          name: "MongoDB",
          url: "https://www.mongodb.com/",
          image: "https://cdn.worldvectorlogo.com/logos/mongodb-icon-1.svg",
        },
        {
          name: "Express JS",
          url: "https://expressjs.com/",
          image: "https://cdn.worldvectorlogo.com/logos/express-109.svg",
        },
      ],
    },
    {
      title: "School HELP",
      description: "I developed a web application for managing schools donation during the post-covid period using modern technologies like Laravel and Bootstrap CSS. I am responsible for creating a responsive and user-friendly interface.",
      image: school,
      github: "https://github.com/renaldhif/schoolHELP",
      demo: "https://www.schoolhelp.mahendraketut.my.id/public/",
      technology: [
        {
          name: "Laravel",
          url: "https://laravel.com/",
          image: "https://cdn.worldvectorlogo.com/logos/laravel-3.svg",
        },
        {
          name: "Bootstrap",
          url: "https://getbootstrap.com/",
          image: "https://cdn.worldvectorlogo.com/logos/bootstrap-5-1.svg",
        },
        {
          name: "MySQL",
          url: "https://www.mysql.com/",
          image: "https://cdn.worldvectorlogo.com/logos/mysql-logo.svg",
        },
        {
          name: "PHP",
          url: "https://www.php.net/",
          image: "https://cdn.worldvectorlogo.com/logos/php-1.svg",
        },
      ],
    },
  ];

  return (
    <section id="projects" className="w-full h-fit py-10 lg:py-20 font-jakarta">
      <div className="container mx-auto flex flex-col items-center lg:flex-row py-5">
        <div className="w-full lg:w-1/2 h-full ">
          <div className="px-5 lg:px-0 pt-16 lg:py-20 h-full">
            <h1 className="w-full text-center lg:text-start text-5xl lg:text-7xl font-medium">My Portfolio</h1>
            <h1 className="w-full text-center lg:text-start text-6xl lg:text-9xl font-extrabold mt-2 text-blue-800">Projects</h1>
            <p className="text-center lg:text-start lg:w-3/4 leading-snug lg:leading-loose mt-5 text-lg lg:text-xl text-gray-600 ">Here are some of the projects I have worked on</p>
          </div>
        </div>
        <div className="w-full lg:w-1/2 h-full mt-5 lg:mt-0">
          <>
            <Swiper
              slidesPerView={1}
              spaceBetween={5}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {projects.map((project, index) => (
                <SwiperSlide key={index}>{ProjectCard(project.title, project.description, project.image, project.github, project.demo, project.technology)}</SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
      </div>
    </section>
  );
};

export default portfolioSection;

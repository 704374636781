import React from "react";
import HeroImg from "../../assets/hero.png";
import myImg from "../../assets/myImg.png";
import BtnClickHover from "../../Components/buttons/BtnClickHover";
import { motion } from "framer-motion";

export default function Hero() {
  const styleBg = {
    transition: "background-color 0.3s ease" /* Add transition for smoother effect */,
    backgroundColor: "rgb(219, 234, 254)" /* Initial background color */,
    backgroundImage:
      "radial-gradient(at 63.77% 67.17%, rgb(255, 255, 255) 0, transparent 77%), radial-gradient(at -2.49% -1.50%, rgb(147, 197, 253) 0, transparent 68%), radial-gradient(at 98.17% 0.67%, rgb(219, 234, 254) 0, transparent 43%)",
  };

  /* Style when scrolled */

  return (
    /*
    Section hero started here.  It is the first section of the website, and it contains an image and a button to navigate to
    the projects section and a button to download the CV.
    component used:
    - BtnClickHover
    - motion.div
    @author: Mahendra
    Note:
    TODO 1: make the background more intuitive
    TODO 2: in some cases, in mobile the bg is not full width
    */

    <section id="hero" className="hero-section font-jakarta">
      <div className=" bg-slate-50 min-h-screen w-full pb-20 lg:pb-0" style={styleBg}>
        <div className="container mx-auto ">
          <div className=" lg:hidden w-full h-fit align-middle px-10">
            <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
              <img src={HeroImg} alt="hero" className="w-full pt-10 h-full object-cover rounded-full" />
            </motion.div>
          </div>
          <div className="flex items-center justify-between lg:h-screen">
            <motion.div initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
              <div className=" px-5 lg:px-0 pt-16 lg:py-20">
                <h1 className="w-full text-center lg:text-start text-5xl lg:text-7xl font-medium">Hey there, I’m</h1>
                <h1 className="w-full text-center lg:text-start text-6xl lg:text-9xl font-extrabold mt-2 text-blue-800">Mahendra</h1>
                <p className="text-center lg:text-start lg:w-3/4 leading-snug lg:leading-loose mt-5 text-lg lg:text-xl text-gray-600 ">
                  Experienced Frontend Developer and passionate about creating user-friendly interfaces for websites. Skilled in crafting responsive and intuitive web experiences.
                </p>
                <div className=" md:hidden">
                  <div className="mt-10 flex items-center justify-center lg:justify-start ">
                    <BtnClickHover href="#projects" text="View Projects" style="view-projects-button" position="right" icon="" target="" />
                    <BtnClickHover href="https://drive.google.com/file/d/19X3JK_fupcgPxrUdD7hOh3lf-C8dhJpC/view?usp=sharing" text="Download CV" style="download-cv-button" position="left" icon="" target="blank" />
                  </div>
                </div>

                <div className="hidden md:block">
                  <div className="mt-10 flex items-center justify-center lg:justify-start ">
                    <BtnClickHover href="#projects" text="View Projects" style="view-projects-button" position="right" icon="fa-solid fa-arrow-right " target="" />
                    <BtnClickHover href="https://drive.google.com/file/d/19X3JK_fupcgPxrUdD7hOh3lf-C8dhJpC/view?usp=sharing" text="Download CV" style="download-cv-button" position="left" icon="fa-solid fa-download" target="blank" />
                  </div>
                </div>
              </div>
            </motion.div>

            <div className="hidden lg:block lg:w-3/4 ">
              <div className="flex items-end h-screen my-auto mt-40 align-middle">
                <motion.div initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 1 }}>
                  <img src={myImg} alt="hero" className="w-full h-full object-cover  bottom-0" />
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

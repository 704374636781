import Hero from "../Hero";
import Navbar from "../Navbar";
import AboutMe from "../AboutMe";
import Projects from "../MyPortfolio";
import Skills from "../MySkills";
import ContactMe from "../ContactMe";

import Footer from "../Footer";

export default function Homescreen() {
  return (
    <>
      <Navbar />
      <Hero />
      <AboutMe />

      <Skills />
      <Projects />
      <ContactMe />

      <Footer />
    </>
  );
}

import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { scrollTo } from "../../utils/scroll.js";

export default function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const controls = useAnimation();

  const menuList = [
    {
      name: "Home",
      link: "#hero",
    },
    {
      name: "About",
      link: "#about",
    },

    {
      name: "Skills",
      link: "#skills",
    },
    {
      name: "Projects",
      link: "#projects",
    },
    // {
    //   name: "Publications",
    //   link: "#publications",
    // },
    // {
    //   name: "Blog",
    //   link: "#blog",
    // },
  ];

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    controls.start(isMenuOpen ? { x: "0%" } : { x: "-100%" });
  };

  const closeMenu = () => {
    setMenuOpen(false);
    controls.start({ x: "-100%" }); // Reset animation when the menu is closed
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 50;
      const scrolled = window.scrollY > scrollThreshold;
      setScrolled(scrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={`nav__navbar ${isScrolled && "bg-blur-lg"} ${isScrolled && "bg-white ease-in"} ${isMenuOpen && "bg-white"} p-4 fixed w-full top-0 z-10 font-jakarta`}>
      <div className="container mx-auto flex justify-between items-center">
        <div>
          <a href="/" className="text-2xl font-bold text-blue-800">
            Mahendra.
          </a>
        </div>

        {/* Hamburger menu for small screens */}
        <div className="lg:hidden">
          <motion.button onClick={toggleMenu} className="text-2xl text-blue-800 focus:outline-none" whileTap={{ scale: 0.9 }}>
            <motion.span animate={isMenuOpen ? { rotate: 45, y: 4 } : { rotate: 0, y: 0 }} transition={{ duration: 0.3 }} className="block w-6 h-1 bg-blue-800 mb-1"></motion.span>
            <motion.span animate={isMenuOpen ? { rotate: -45, y: -4 } : { rotate: 0, y: 0 }} transition={{ duration: 0.3 }} className="block w-6 h-1 bg-blue-800 mb-1"></motion.span>
            <motion.span animate={isMenuOpen ? { opacity: 0 } : { opacity: 1 }} transition={{ duration: 0.3 }} className="block w-6 h-1 bg-blue-800 mb-1"></motion.span>
          </motion.button>
        </div>

        {/* Regular menu for larger screens */}
        <div className="hidden lg:flex space-x-4 items-center">
          {menuList.map((menu, index) => (
            <a href={menu.link} className="block px-3 text-gray-600 py-2 hover:text-[#002C6D] hover:font-medium active:text-blue-800" key={index}>
              {menu.name}
            </a>
          ))}
          <a href="#contact" className="block px-6 rounded-2xl border-b-4 text-white py-4 bg-[#002C6D] hover:bg-[#002C6D] hover:font-medium">
            <i className="fa-solid fa-phone mr-3"></i>
            Contact
          </a>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <motion.div
            id="mobileMenu"
            className={`lg:hidden absolute top-16 left-0 right-0 w-full ${!isScrolled && "bg-white"} ${isScrolled && "bg-white"} ${!isMenuOpen && "ease-out"} p-4 h-fit pb-10 rounded-b-3xl shadow-md`}
            animate={controls} // Apply animation controls
          >
            {menuList.map((menu, index) => (
              <motion.a
                href={menu.link}
                className="block text-gray-600 mb-10 px-2 text-center active:font-semibold"
                key={index}
                whileHover={{ scale: 1.1 }} // Example: Scale up on hover
              >
                {menu.name}
              </motion.a>
            ))}
            <motion.a
              href="#contact"
              className=" text-white py-4 px-5 w-fit bg-[#002C6D] rounded-2xl text-left"
              whileTap={{ scale: 1.1 }} // Example: Scale up on hover
            >
              <i className="fa-solid fa-phone mr-3"></i>
              Contact
            </motion.a>
          </motion.div>
        )}
      </div>
    </nav>
  );
}

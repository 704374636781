import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import EducationCard from "../../Components/cards/EducationCard";
import WorkingExperienceCard from "../../Components/cards/WorkingExperienceCard";
import HelpLogo from "../../assets/help.png";
import StikomLogo from "../../assets/stikom.png";
import timedoorLogo from "../../assets/timedoor.png";
import fastworkLogo from "../../assets/fastwork.png";
import OneByOne from "../../assets/624897_782151.webp";

const AboutSection = () => {
  const [isScrolled, setScrolled] = useState(false);
  const controls = useAnimation(); // Initialize controls for animation

  //list the education
  const educationList = [
    {
      logo: HelpLogo,
      title: "HELP University",
      link: "https://university.help.edu.my/",
      subtitle: "Bachelor of Information Technology Honours Degree",
      date: "September 2019 - May 2024",
      // description: "I am currently pursuing my Bachelor's degree in Computer Science from the University of Delhi.",
      gpa: 3.21,
    },
    {
      logo: StikomLogo,
      title: "Institut Teknologi dan Bisnis STIKOM Bali",
      link: "https://stikom-bali.ac.id/",
      subtitle: "Sarjana Komputer - Information System",
      date: "September 2019 - January 2024",
      // description: "I completed my Diploma in Information Technology from STIKOM Bali, Indonesia.",
      gpa: 3.56,
    },
  ];

  const workingLists = [
    {
      logo: fastworkLogo,
      link: "https://www.fastwork.co/",
      title: "Fastwork",
      position: "Graphic Designer",
      type: "Freelance",
      date: "April 2022 - presents",
      description: "I worked as a freelancer and developed websites for various clients. I was responsible for creating responsive and user-friendly interfaces.",
    },
    {
      logo: timedoorLogo,
      link: "https://timedoor.net/",
      title: "Timedoor Indonesia",
      position: "Web Quality Assurance",
      type: "Internship",
      date: "June 2023 - September 2023",
      description: "I worked as an intern at Timedoor Indonesia and was responsible for developing and maintaining the company's website.",
    },

    {
      logo: OneByOne,
      link: "https://www.onebyone.io/",
      title: "OneByOne Logistical",
      position: "QA Engineer",
      type: "Full Time",
      date: "July 2024 - present",
      description: "I worked as a fulltime QA Engineer and was responsible to ensure the quality of the software (products)",
    },
  ];

  const latestWorkingExperiences = workingLists.slice(-2);

  const aboutDesc =
    " I am a passionate and self-motivated Information System student with a strong foundation in programming and web development. I am currently pursuing my Bachelor's degree in Information Technology at ITB STIKOM Bali and HELP University. I am a quick learner and a team player who is always ready to learn new technologies and adapt to new environments.";

  const containerVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { duration: 1 } },
  };

  const staggerVariants = {
    hidden: { y: -100, opacity: 0 },
    visible: (index) => ({
      y: 0,
      opacity: 1,
      transition: { duration: 1, delay: index * 0.2 },
    }),
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 50;
      const scrolled = window.scrollY > scrollThreshold;
      setScrolled(scrolled);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove scroll event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Check if the "About Me" section is in view
    const aboutSection = document.getElementById("about");
    if (aboutSection) {
      const rect = aboutSection.getBoundingClientRect();
      const isInView = rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2;

      // Trigger animation based on scroll and whether the section is in view
      controls.start(isScrolled && isInView ? { scale: 1.1 } : { scale: 1 });
    }
  }, [isScrolled, controls]);

  return (
    <section id="about" className="w-full h-full overflow-hidden relative py-20 bg-white font-jakarta">
      <div className="container mx-auto ">
        <div>
          <motion.h2
            className="text-5xl font-semibold text-center pt-5"
            animate={controls} // Apply animation controls
          >
            About Me
          </motion.h2>
          <div className="flex items-center justify-center">
            <div className="w-20 h-1 bg-blue-800 mt-3 mb-10"></div>
          </div>
        </div>
        <div className="p-5">
          <motion.p className="text-center text-lg lg:text-xl text-gray-600 px-5 lg:leading-loose" animate="visible" variants={containerVariants}>
            {aboutDesc}
          </motion.p>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <motion.div className="mt-10 flex flex-col items-start" animate="visible" variants={containerVariants}>
              <motion.h2 className="text-2xl font-semibold text-center lg:text-left pt-5" variants={staggerVariants}>
                Education
              </motion.h2>
              <div className="flex items-center justify-center lg:justify-start">
                <div className="w-20 h-1 bg-blue-800 mt-3 mb-10"></div>
              </div>
              <motion.div className="lg:flex flex-col items-start justify-start" variants={staggerVariants}>
                {educationList.map((education, index) => (
                  <motion.div key={index} custom={index} variants={staggerVariants}>
                    <EducationCard logo={education.logo} title={education.title} link={education.link} subtitle={education.subtitle} date={education.date} description={education.description} gpa={education.gpa} />
                  </motion.div>
                ))}
              </motion.div>
              <a className="hover:font-semibold hover mt-5">
                Discover My Educational Odyssey
                <span>
                  <i className="fa-solid fa-arrow-right ml-2"></i>
                </span>
              </a>
            </motion.div>
            <motion.div className="mt-10 flex flex-col items-start" animate="visible" variants={containerVariants}>
              <motion.h2 className="text-2xl font-semibold text-center lg:text-left pt-5" variants={staggerVariants}>
                Working Experience
              </motion.h2>
              <div className="flex items-center justify-center lg:justify-start">
                <div className="w-20 h-1 bg-blue-800 mt-3 mb-10"></div>
              </div>
              <motion.div className="lg:flex flex-col items-start justify-start" variants={staggerVariants}>
                <motion.div variants={staggerVariants}>
                  {latestWorkingExperiences.map((working, index) => (
                    <WorkingExperienceCard key={index} logo={working.logo} title={working.title} link={working.link} subtitle={working.position} type={working.type} date={working.date} description={working.description} />
                  ))}
                </motion.div>
              </motion.div>

              <a className="hover:font-semibold hover mt-5">
                Uncover My Professional Journey
                <span>
                  <i className="fa-solid fa-arrow-right ml-2"></i>
                </span>
              </a>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;

import React from "react";

import { motion, useAnimation } from "framer-motion";
import { useForm, ValidationError } from "@formspree/react";

const ContactForm = () => {
  const controls = useAnimation(); // Initialize controls for animation
  const [state, handleSubmit] = useForm("mzbnegzl");
  if (state.succeeded) {
    return (
      <div className="container mx-auto rounded-3xl w-full h-full text-white px-5 lg:px-16 font-jakarta">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="w-full py-10 lg:pr-10">
            <h2 className="text-xl lg:text-2xl font-semibold text-center px-5">
              Thank you for reaching out to me. <br /> I will get back to you soon!
            </h2>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="w-full h-full flex items-center justify-center">
      <form action="https://formspree.io/f/mzbnegzl" method="POST" className="flex flex-col items-start justify-center w-full" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 lg:grid-cols-2 w-full lg:gap-4">
          <div className="flex flex-col items-start justify-start">
            <label htmlFor="name" className="text-base mb-2">
              Your Name
            </label>
            <input type="text" name="name" placeholder="Place your name here" className="w-full p-3 mb-5 rounded-xl text-[#002C6D]" />
            <ValidationError field="name" prefix="Name" errors={state.errors} />
          </div>
          <div className="flex flex-col items-start justify-start">
            <label htmlFor="email" className="text-base mb-2">
              Your Email
            </label>
            <input type="email" name="email" placeholder="Place your email here" className="w-full p-3 mb-5 rounded-xl text-[#002C6D]" />
            <ValidationError field="email" prefix="Email" errors={state.errors} />
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <label htmlFor="subject" className="text-base mb-2">
            Subject
          </label>
          <input type="text" name="subject" placeholder="Place your subject here" className="w-full p-3 mb-5 rounded-xl text-[#002C6D]" />
          <ValidationError field="subject" prefix="Subject" errors={state.errors} />
        </div>
        <div className="flex flex-col items-start justify-start w-full">
          <label htmlFor="message" className="text-base mb-2">
            Your Message
          </label>
          <textarea name="message" placeholder="Your Message" className="w-full h-40 p-3 mb-5 rounded-xl text-[#002C6D]"></textarea>
          <ValidationError field="message" prefix="Message" errors={state.errors} />
        </div>

        <button type="submit" className="view-projects-button w-full p-3 bg-white text-[#002C6D] rounded-3xl hover:bg-[#002C6D] hover:text-white">
          Send Message
        </button>
      </form>
    </div>
  );
};

export default ContactForm;

import React from "react";
import { motion, useAnimation } from "framer-motion";

const ProjectsCard = (title, description, image, github, demo, technology) => {
  const styleBg = {
    transition: "background-color 0.3s ease" /* Add transition for smoother effect */,
    backgroundColor: "rgb(219, 234, 254)" /* Initial background color */,
    backgroundImage:
      "radial-gradient(at 63.77% 67.17%, rgb(255, 255, 255) 0, transparent 77%), radial-gradient(at -2.49% -1.50%, rgb(147, 197, 253) 0, transparent 68%), radial-gradient(at 98.17% 0.67%, rgb(219, 234, 254) 0, transparent 43%)",
  };
  return (
    <div className=" w-full px-5 text-[#002C6D] font-jakarta">
      <div className=" shadow-lg h-full mb-10 p-4 rounded-3xl bg-slate-100">
        <div className="w-full h-auto bg-white rounded-2xl mx-auto mb-3 overflow-hidden">
          <motion.img src={image} alt="skill" className="object-cover rounded-sm w-full " whileHover={{ scale: 1.2 }} />
        </div>

        <div className="text-center mb-4">
          <h2 className="text-lg  font-semibold">{title}</h2>

          <p className="text-sm pb-5">{description}</p>
          <h3 className="text-sm">Technology that I use</h3>
          <div className="flex flex-wrap items-center justify-center w-full gap-2 pt-5">
            {technology.map((tech, index) => (
              <div className="flex items-center justify-center rounded-full w-8 h-8 border p-2 border-[#002C6D] text-[#002C6D] hover:bg-[#002C6D] hover:text-white" key={index}>
                <img src={tech.image} alt={tech.name} />
              </div>
            ))}
          </div>
          <p className="text-sm my-2">See my project on: </p>
          <div className="flex items-center justify-center w-full gap-2">
            <div className="flex items-center justify-center rounded-full w-8 h-8 border p-2 border-[#002C6D] text-[#002C6D] hover:bg-[#002C6D] hover:text-white">
              <a href={github} target="_blank" rel="noopener noreferrer">
                <i className="fab fa-github "></i>
              </a>
            </div>
            <div className="flex items-center justify-center rounded-full w-8 h-8 border p-2  border-[#002C6D] text-[#002C6D] hover:bg-[#002C6D] hover:text-white">
              <a href={demo} target="_blank" rel="noopener noreferrer">
                <i className="fa-solid fa-link "></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsCard;

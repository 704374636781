import React from "react";
import { motion } from "framer-motion";

const BtnClickHover = ({ href, text, style, position, icon, target }) => {
  const iconStyle = {
    marginLeft: position === "right" ? "8px" : "0",
    marginRight: position === "left" ? "8px" : "0",
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
    },
    tap: {
      scale: 0.95,
    },
  };

  return (
    <motion.a href={href} target={target} className={style} whileHover="hover" whileTap="tap" variants={buttonVariants}>
      {position === "left" && <motion.i className={icon} style={iconStyle} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}></motion.i>}
      {text}
      {position === "right" && <motion.i className={icon} style={iconStyle} whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }}></motion.i>}
    </motion.a>
  );
};

export default BtnClickHover;

import React, { useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import SkillCard from "../../Components/cards/skillsCard";

const MySkills = () => {
  const controls = useAnimation(); // Initialize controls for animation
  const styleBg = {
    transition: "background-color 0.3s ease" /* Add transition for smoother effect */,
    backgroundColor: "rgb(219, 234, 254)" /* Initial background color */,
    backgroundImage:
      "radial-gradient(at 63.77% 67.17%, rgb(255, 255, 255) 0, transparent 77%), radial-gradient(at -2.49% -1.50%, rgb(147, 197, 253) 0, transparent 68%), radial-gradient(at 98.17% 0.67%, rgb(219, 234, 254) 0, transparent 43%)",
  };
  const techStack = [
    {
      name: "HTML",
      icon: "https://img.icons8.com/color/96/000000/html-5.png",
    },
    {
      name: "CSS",
      icon: "https://img.icons8.com/color/96/000000/css3.png",
    },
    {
      name: "JavaScript",
      icon: "https://img.icons8.com/color/96/000000/javascript.png",
    },
    {
      name: "React",
      icon: "https://img.icons8.com/color/96/000000/react-native.png",
    },
    {
      name: "Angular",
      icon: "https://img.icons8.com/color/96/000000/angularjs.png",
    },
    {
      name: "PHP",
      icon: "https://img.icons8.com/ios-filled/96/php-logo.png",
    },
    {
      name: "Laravel",
      icon: "https://img.icons8.com/ios-filled/96/000000/laravel.png",
    },
    {
      name: "Java",
      icon: "https://img.icons8.com/color/96/000000/java-coffee-cup-logo.png",
    },
    {
      name: "Python",
      icon: "https://img.icons8.com/color/96/000000/python.png",
    },
    {
      name: "MySQL",
      icon: "https://img.icons8.com/ios-filled/96/000000/mysql-logo.png",
    },
    {
      name: "Node.js",
      icon: "https://img.icons8.com/color/96/000000/nodejs.png",
    },
    {
      name: "MongoDB",
      icon: "https://img.icons8.com/external-tal-revivo-color-tal-revivo/90/external-mongodb-a-cross-platform-document-oriented-database-program-logo-color-tal-revivo.png",
    },
    {
      name: "Git",
      icon: "https://img.icons8.com/color/96/000000/git.png",
    },
    {
      name: "Bootstrap",
      icon: "https://img.icons8.com/color-glass/96/bootstrap.png",
    },
    {
      name: "Tailwind CSS",
      icon: "https://img.icons8.com/fluency/96/tailwind_css.png",
    },
    {
      name: "Figma",
      icon: "https://img.icons8.com/color/96/000000/figma.png",
    },
    {
      name: "Adobe XD",
      icon: "https://img.icons8.com/color/96/000000/adobe-xd.png",
    },
    {
      name: "Photoshop",
      icon: "https://img.icons8.com/color/96/000000/adobe-photoshop.png",
    },
    {
      name: "Illustrator",
      icon: "https://img.icons8.com/color/96/000000/adobe-illustrator.png",
    },
  ];

  const skill = [
    {
      name: "Front-End Web Development",
      icon: "https://images.unsplash.com/photo-1542831371-29b0f74f9713?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "I build user-friendly websites using HTML, CSS, and JavaScript. I'm experienced with modern frameworks like React and Angular for web applications.",
    },
    {
      name: "Web Application Development",
      icon: "https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "I develop web applications using modern technologies like Laravel and Angular. I'm experienced in creating responsive and user-friendly interfaces.",
    },
    {
      name: "Software Quality Assurance (Tester)",
      icon: "https://images.unsplash.com/photo-1531403009284-440f080d1e12?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "I am able to build a test plan, approach, scope, test case and performing the test to ensure the quality of product (software)",
    },
    {
      name: "UI/UX Design",
      icon: "https://images.unsplash.com/photo-1635405074683-96d6921a2a68?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "I am passionate about creating user-friendly interfaces for websites and web applications. I am skilled in crafting responsive and intuitive web experiences.",
    },
  ];

  return (
    <section id="skills" className="w-full h-full py-20 bg-white font-jakarta" style={styleBg}>
      <div className="container mx-auto ">
        <div>
          <motion.h2
            className="text-5xl font-semibold text-center pt-5"
            animate={controls} // Apply animation controls
          >
            Skills
          </motion.h2>
          <div className="flex items-center justify-center">
            <div className="w-20 h-1 bg-blue-800 mt-3 mb-10"></div>
          </div>
          <>
            <Swiper
              slidesPerView={1}
              spaceBetween={5}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                "@1.00": {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {skill.map((skill, index) => (
                <SwiperSlide key={index}>{SkillCard(skill.icon, skill.name, skill.description)}</SwiperSlide>
              ))}
            </Swiper>
          </>
        </div>
        <motion.h2
          className="text-3xl font-semibold text-center pt-10"
          animate={controls} // Apply animation controls
        >
          Technology that I use
        </motion.h2>
        <div className="flex items-center justify-center">
          <div className="w-20 h-1 bg-blue-800 mt-3 mb-10"></div>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-6 sm:gap-2 lg:gap-x-14">
          {techStack.map((tech, index) => (
            <motion.div className="flex flex-col my-3 items-center justify-center" key={index} initial={{ y: 100, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { duration: 1, delay: index * 0.2 } }}>
              <img src={tech.icon} alt={tech.name} />
              <p className="text-sm">{tech.name}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};
export default MySkills;

import "./App.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Homescreen from "./Pages/Home/Homescreen";
import AboutMe from "./Pages/Home/AboutMe";

function App() {
  return (
    <div className="App">
      <Homescreen />
    </div>
  );
}

export default App;

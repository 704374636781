import React from "react";
import { motion, useAnimation } from "framer-motion";

const SkillCard = (img, title, description) => {
  return (
    <div className=" w-full px-5 text-[#002C6D] font-jakarta">
      <div className="bg-slate-100 shadow-lg h-full mb-10 p-3 rounded-3xl">
        <div className="w-full h-60 bg-white rounded-3xl mx-auto mb-3 overflow-hidden">
          <motion.img src={img} alt="skill" className="object-cover rounded-sm w-full " whileHover={{ scale: 1.2 }} />
        </div>

        <div className="text-center">
          <h2 className="text-base  font-semibold">{title}</h2>
          <p className="text-sm pb-5">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SkillCard;
